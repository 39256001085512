import React, { Component } from 'react';

import Main from './MainContent.js';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import { Redirect } from "react-router-dom";

class AdminPanel extends Component {
  state = {
    isLogged: true
  }
  render() {
    if (this.state.isLogged) {
      return (
        <div>
          <Header />
          <Sidebar />
          <Main />
        </div>
      )
    }
    return <Redirect push to="/login" />;
  }
}
export default AdminPanel;