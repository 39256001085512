import React, { useState, useEffect, createRef } from 'react';


import SignatureCanvas from 'react-signature-canvas'
import MDUIkit from 'md-uikit';
import UIkit from 'uikit';
import { useParams } from "react-router-dom";

import DoneImg from "../../assets/img/done.png"
import Logo from "../../assets/img/logo_institucion.jpg";
import Countries from '../../assets/data/countries.json';

import { config, settings } from '../../settings.js';
const Home = () => {
    const [code, setCode] = useState("0");
    const [text, setText] = useState({});
    const [tempCode, setTempCode] = useState(); // when there is no param they should send a code
    const [eventInfo, setEventInfo] = useState({});
    const [sign, setSign] = useState(false);
    const [registroId, setRegistroId] = useState("");
    const [checkedContinue, setCheckedContinue] = useState(false);
    const [personalData, setPersonalData] = useState({});

    let signatureRef = createRef();
    let { codeParam, lang } = useParams();

    useEffect(() => {
        //MDUIkit.components();
        setText(settings.language[lang]["signup_form"]);
        setCode(codeParam);
        fetchInfoEvent(codeParam);
        console.log('aqui 3');
    }, []);
    async function fetchInfoEvent(code) {
        const data = {
            "code": code
        };
        console.log(data);
        console.log(`${config.api_path}/api/read_event_getEventById.php`);
        fetch((`${config.api_path}/api/read_event_getEventById.php`), {
            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                console.log("read_event_getEventById");
                console.log(data);
                setEventInfo(data);
                MDUIkit.components();
            })
            .catch(data => {
                console.log(data);
                UIkit.notification('Error.' + data);
            })
    }
    function ValidateCode() {
        setCode(tempCode);
        fetchInfoEvent(tempCode);
    }
    const handleChange = event => {
        const { value, name } = event.target;
        setTempCode(value);
    }
    const clearCanvas = (e) => {
        e.preventDefault();
        signatureRef.clear();
        setSign(false);
    }

    /* const trimCanvas = () => {
        this.setState({
            trimmedDataURL: signatureRef.getTrimmedCanvas()
                .toDataURL('image/png')
        })
    } */
    const handlePersonalInfo = event => {
        const { value, name } = event.target;
        setPersonalData({ ...personalData, [name]: value })
    }
    function fetchInfoParticipant() {
        const data = {
            "event_id": eventInfo.id,
            "email": personalData.email
        };
        console.log(data);
        fetch((`${config.api_path}/api/event_participant_getparticipantByEmail.php`), {
            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.status >= 400 && response.status < 500) {
                    throw "No info email";
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                setPersonalData(data);
                MDUIkit.components();
            })
            .catch(data => {
                console.log(data);
            })
    }
    async function insertPerson() {
        console.log("insertPerson...");

        try {
            const data = {
                "firstname": personalData.firstname,
                "lastname": personalData.lastname,
                "email": personalData.email,
                "phone": personalData.phone,
                "city": personalData.city,
                "country": personalData.country,
                "institution": personalData.institution,
                "gender": personalData.gender,
                "address": personalData.address
            };
            console.log(data);
            const response = await fetch((`${config.api_path}/api/person_insert_update.php`), {
                method: 'POST',
                body: JSON.stringify(data)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();
            console.log(res);
            insertEventParticipant(res.id, res.token);

        } catch (error) {
            console.log(error)
            UIkit.notification('Error.' + error);
        }
    }
    const onClickSend = event => {
        let flagWhites = true;
        let flagGeneral = true;

        if (personalData.email == undefined || personalData.firstname == undefined || personalData.lastname == undefined || personalData.phone == undefined || personalData.gender == undefined) {
            flagWhites = false;
        }
        if (eventInfo.status == "Activo") {
            if (sign === false) {
                flagGeneral = false;
                UIkit.notification('Firme este documento. ');
            }
            if (checkedContinue === false) {
                flagGeneral = false;
                UIkit.notification('Haga click de verificación en la declaración.');
            }
        }
        if (!flagWhites) {
            flagGeneral = false;
            UIkit.notification('Complete los espacios en blanco.', 'danger');
        }
        if (flagGeneral) {
            let btn_enviar = document.querySelector("#btn_enviar");
            btn_enviar.innerHTML = 'ENVIANDO...';
            btn_enviar.disabled = 'true';
            insertPerson();
        }
    }
    async function insertEventParticipant(id, token) {

        console.log("insertEventParticipant...");
        /* Validaciones */
        let flag = true;
        if (code === "") {
            flag = false;
        }
        if (flag) {
            try {
                const data = {
                    "person_id": id,
                    "code": code,
                    "jwt": token
                };
                console.log(data);
                const response = await fetch((`${config.api_path}/api/insert_event_participant.php`), {
                    method: 'POST',
                    body: JSON.stringify(data)
                });
                if (response.status >= 400 && response.status < 500) {
                    if (eventInfo.status !== "Activo") {
                        UIkit.notification('Ya estas registrado a este evento.');
                        let btn_enviar = document.querySelector("#btn_enviar");
                        btn_enviar.innerHTML = 'ENVIAR';
                        btn_enviar.disabled = false;
                        throw "Ya existe";
                    }
                }
                if (response.status >= 500 && response.status < 600) {
                    UIkit.notification('Error. Please try later.');
                }
                const res = await response.json();
                console.log(res);
                if (eventInfo.status === "Activo") {
                    updateEventParticipant(res.id, token);
                } else {
                    UIkit.modal('#modal', { 'bgClose': false, 'escClose': false }).show();
                }

            } catch (error) {
                console.log(error)
                UIkit.notification('Error.' + error);
            }
        } else {
            alert('Please fill blank spaces');
        }

    }
    async function updateEventParticipant(id, token) {
        console.log("updateEventParticipant...");
        /* Validaciones */
        let flag = true;

        console.log("signatureRef");
        console.log(signatureRef);
        const trimmedDataURL = signatureRef.getTrimmedCanvas().toDataURL('image/png');
        if (flag) {
            console.log(trimmedDataURL);
            try {
                const data = {
                    "id": id,
                    "jwt": token,
                    "sign_image": trimmedDataURL
                };
                console.log(data);
                const response = await fetch((`${config.api_path}/api/update_event_participant.php`), {
                    method: 'POST',
                    body: JSON.stringify(data)
                });
                if (response.status >= 400 && response.status < 500) {
                    UIkit.notification('Error.');
                }
                if (response.status >= 500 && response.status < 600) {
                    UIkit.notification('Error. Please try later.');
                }
                const res = await response.json();
                console.log(res);
                UIkit.modal('#modal', { 'bgClose': false, 'escClose': false }).show();

            } catch (error) {
                console.log(error)
                UIkit.notification('Error.' + error);
            }
        } else {
            UIkit.notification('Please fill blank spaces');
        }
    }
    const searchEmail = (e) => {
        console.log(e);
        fetchInfoParticipant();
    }
    const onCheckContinue = (e) => {
        setCheckedContinue(!checkedContinue);
    }

    const ref = sigObj => {

        console.log("settings ref");
        signatureRef = sigObj;
    }
    return (
        <div className="uk-grid-match uk-grid-small uk-padding-small" data-uk-height-viewport data-uk-grid
            style={{ backgroundColor: '#e8eaf6' }}>
            <div className="uk-width-1-6 uk-visible@s"></div>
            <div className="uk-width-2-3@s "> {/* uk-position-center */}
                {eventInfo.id !== undefined ? (
                    eventInfo.status != 'Inactivo' ? (
                        <div className="uk-child-width-1-1" data-uk-card>
                            <div className="uk-card md-bg-white uk-card-body">
                                <img className="uk-align-center" src={Logo} width='220px' alt='' />
                                {/* <div className='md-bg-indigo-700'>&nbsp;</div> */}
                                <h3>{eventInfo.title}</h3>
                                <p>{eventInfo.description}</p>
                                <p className='md-color-red-500'>{text["p_mandatory"]}</p>
                            </div>
                            <div className="uk-card md-bg-white uk-card-body uk-margin-small">
                                <div className="md-input-wrapper">
                                    <label>{text["label_email"]}*</label>
                                    <input required name='email' onBlur={searchEmail} onChange={handlePersonalInfo} type="text" className="md-input" />
                                    <span className="md-input-bar"></span>
                                    <p></p>
                                </div>
                            </div>
                            <div className="uk-card md-bg-white uk-card-body uk-margin-small">
                                <div className="md-input-wrapper ">
                                    <label>{text["label_name"]}*</label>
                                    <input required name='firstname' onChange={handlePersonalInfo} type="text" className="md-input" value={personalData.firstname} />
                                    <span className="md-input-bar"></span>
                                </div>
                            </div>
                            <div className="uk-card md-bg-white uk-card-body uk-margin-small">
                                <div className="md-input-wrapper">
                                    <label>{text["label_lastname"]}*</label>
                                    <input required name='lastname' onChange={handlePersonalInfo} type="text" className="md-input" value={personalData.lastname} />
                                    <span className="md-input-bar"></span>
                                </div>
                            </div>
                            <div className="uk-card md-bg-white uk-card-body uk-margin-small">
                                <div className="md-input-wrapper">
                                    <label>{text["label_phone"]}*</label>
                                    <input required name='phone' onChange={handlePersonalInfo} type="text" className="md-input" value={personalData.phone} />
                                    <span className="md-input-bar"></span>
                                </div>
                            </div>
                            <div className="uk-card md-bg-white uk-card-body uk-margin-small">
                                <div className="md-input-wrapper">
                                    <label>{text["label_institution"]}*</label>
                                    <input required name='institution' onChange={handlePersonalInfo} type="text" className="md-input" value={personalData.institution} />
                                    <span className="md-input-bar"></span>
                                </div>
                            </div>
                            <div className="uk-card md-bg-white uk-card-body uk-margin-small">
                                <div className="md-input-wrapper">
                                    <label>{text["label_address"]}*</label>
                                    <input required name='address' onChange={handlePersonalInfo} type="text" className="md-input" value={personalData.address} />
                                    <span className="md-input-bar"></span>
                                </div>
                            </div>
                            <div className="uk-card md-bg-white uk-card-body uk-margin-small">
                                <div className="md-input-wrapper">
                                    <label>{text["label_country"]}</label>
                                    <select className="md-input" onChange={handlePersonalInfo} name='country' value={personalData.country}>
                                        <option disabled selected hidden></option>
                                        {Countries.map((item, index) => (
                                            <option key={index} value={item.nombre}>{item.nombre}</option>
                                        ))}
                                    </select>
                                    <span className="md-input-bar "></span>
                                </div>
                            </div>
                            <div className="uk-card md-bg-white uk-card-body uk-margin-small">
                                <div className="md-input-wrapper">
                                    <label>{text["label_city"]}*</label>
                                    <input required name='city' onChange={handlePersonalInfo} type="text" className="md-input" value={personalData.city} />
                                    <span className="md-input-bar"></span>
                                </div>
                            </div>
                            <div className="uk-card md-bg-white uk-card-body uk-margin-small">
                                <div className="md-input-wrapper">
                                    <label>{text["label_sex"]}</label>
                                    <select className="md-input" onChange={handlePersonalInfo} name='gender' value={personalData.gender}>
                                        <option disabled selected hidden></option>
                                        <option value="M">{text["label_sex_m"]}</option>
                                        <option value="F">{text["label_sex_f"]}</option>
                                        <option value="NN">{text["label_sex_n"]}</option>

                                    </select>
                                    <span className="md-input-bar "></span>
                                </div>
                            </div>
                            {eventInfo.status == "Activo" &&
                                <div className="uk-card md-bg-white uk-card-body uk-margin-small">
                                    <div className="md-checkbox">
                                        <input id="i1" type="checkbox" checked={checkedContinue} onChange={onCheckContinue} />
                                        <label htmlFor="i1">{text["label_accent_terms"].split("|")[0]} <a target="_blank" href={eventInfo.url}>{text["label_terms_and_conditions"]}</a>{text["label_accent_terms"].split("|")[1]}</label>
                                    </div>
                                    <p>{text["label_signature"]}:</p>
                                    <div className="canvas-container">
                                        <SignatureCanvas penColor='blue'
                                            canvasProps={{ className: 'sigPad md-bg-grey-50' }}
                                            onEnd={() => setSign(true)}
                                            ref={ref} />
                                    </div>
                                    <p><button className="md-btn md-btn-text " onClick={clearCanvas} type="button">{text["label_signature_clear"]}</button>
                                    </p>
                                </div>
                            }
                            <p align='right'><a id="btn_enviar" onClick={onClickSend} className="md-btn md-btn-primary ripple-surface">{text["label_send_info"]}</a> </p>

                        </div>
                    ) : (
                            <div className="uk-card md-bg-white uk-card-body uk-position-center uk-margin-small">
                                <img className="uk-align-center" src={Logo} width='220px' alt='' />
                                <h3>{text["label_event_not_available_title"]}</h3>
                                <h4>{eventInfo.title}</h4>
                                <p>{text["label_event_not_available_subtitle"]}</p>
                            </div>
                        )

                ) : (
                        <>
                            <div className="uk-card md-bg-white uk-card-body ">
                                <h3>{text["label_event_welcome"]}</h3>
                                <p>{text["label_event_welcome_subtitle"]}</p>
                                <div className="md-input-wrapper">
                                    <label>{text["label_event_welcome_code"]}</label>
                                    <input name='tempCode' value={tempCode} onChange={handleChange} style={{ textTransform: 'uppercase' }} type="text" className="md-input" />
                                    <span className="md-input-bar"></span>
                                </div>
                            </div>
                            <p align='right'><a onClick={ValidateCode} className="md-btn md-btn-primary md-btn-wave-light">Siguiente</a> </p>
                        </>
                    )}
            </div>
            <div className="uk-width-1-6 uk-visible@s">
            </div>
            <div>
                <div id="modal" data-uk-modal>
                    <div className="uk-modal-dialog uk-margin-auto-vertical">
                        <div className="uk-modal-header">
                            <h2 className="uk-modal-title">Listo!</h2>
                        </div>
                        <div className="uk-modal-body uk-text-center">
                            <img width='50px' src={DoneImg} alt="" />
                            <p>{text["final_message_title"]}</p>
                            <small>{text["final_message_subtitle"]}</small>
                        </div>
                        <div className="uk-modal-footer uk-text-right">
                        </div>
                    </div>
                </div>

            </div>
        </div >
    );
}
export default Home;