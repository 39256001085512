import { useContext } from "react";
import { EventsContext } from './events.provider';
const EventsActions = () => {
    const { state, dispatch } = useContext(EventsContext);
    return {
        eventsArray: state.eventsArray,
        setEventsList: function (item) {
            dispatch({
                type: "SET_EVENTS_LIST",
                payload: item
            })
        },
    }
}
export default EventsActions;

