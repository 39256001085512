import React, { useEffect, useState } from 'react';

import UIkit from 'uikit';
import MDUIkit from 'md-uikit';

import UserActions from '../../providers/user/user.actions';
import { config, settings } from '../../settings.js';

const Home = () => {
    const [newUser, setNewUser] = useState({
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        rol: '',
    });
    const [text, setText] = useState({});
    const [selectedUser, setSelectedUser] = useState([]);
    const [accountsArray, setAccountsArray] = useState([]);

    const user = UserActions();
    function createUser() {
        const button = document.querySelector("#btn_create");
        button.innerHTML = '<div data-uk-spinner></div>';
        button.disabled = true;
        const data = {
            "email": newUser.email,
            "password": newUser.password,
            "firstname": newUser.firstname,
            "lastname": newUser.lastname,
            "rol": newUser.rol,
            "language": newUser.language,
            "type": 'email'
        };
        console.log(data);

        fetch((`${config.api_path}/api/create_user.php`), {

            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(function (response) {
                if (response.status >= 400 && response.status < 500) {
                    UIkit.notification(text["notification_email_registered"]);
                    button.innerHTML = text["modal_new_user_label_create"];
                    button.disabled = false;
                    throw text["notification_email_registered"];
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                window.location.reload();
            })
            .catch(data => {
                console.log(data);
                button.innerHTML = text["modal_new_user_label_create"];
                button.disabled = false;
            })
    }
    const onClickSignUp = async event => {
        const { email, password, firstname, lastname, rol } = newUser;

        /* Validaciones */
        let flag = true;
        if (email === "" || password === "" || firstname === "" || lastname === "" || rol === "") {
            flag = false;
        }
        if (flag) {
            createUser();
        } else {
            alert(text["notification_white_spaces"]);
        }
    }
    const handleChange = event => {
        const { value, name } = event.target;
        setNewUser({ ...newUser, [name]: value })
    }
    const handleChangeEdit = event => {
        const { value, name } = event.target;
        setSelectedUser({ ...selectedUser, [name]: value, })
    }
    function fetchUsers() {
        const data = {
            "jwt": user.currentUser.token
        };
        fetch((`${config.api_path}/api/read_user.php`), {
            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(function (response) {
                return response.json();
            })
            .then(data => {
                console.log(data);
                setAccountsArray(data);
            })
            .catch(data => {
                UIkit.notification('Error.' + data);
                console.log(data);
            })
    }
    useEffect(() => {
        MDUIkit.components();
        fetchUsers();
        setText(settings.language[user.currentUser.language]["users_admin"]);
    }, []);
    const onClickDelete = (id) => (e) => {
        e.preventDefault();
        if (window.confirm(text["notification_confirm_deleting"])) {
            const data = {
                "id": id,
                "jwt": user.currentUser.token
            };
            console.log(data);
            fetch((`${config.api_path}/api/delete_user.php`), {
                method: 'POST',
                body: JSON.stringify(data)
            })
                .then(function (response) {
                    if (response.status >= 400 && response.status < 500) {
                        UIkit.notification('Error.');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log(data);
                    UIkit.notification(text["notification_user_deleted"]);
                    fetchUsers();
                })
                .catch(data => {
                    console.log(data);
                    UIkit.notification('Error.' + data);
                })
        }
    }
    function getSelectedUser(id) {
        return accountsArray.find(event => event.id === id);
    }
    const onClickUpdatePassword = event => {
        if (selectedUser.new_password != '' && selectedUser.new_password != undefined) {
            updateUserPassword('#modal-edit-password');
        } else {
            alert(text["notification_write_password"]);
        }
    }
    const onClickUpdate = event => {
        updateUser('#modal-edit');
    }
    function updateUserPassword(modal_name) {
        const data = {
            "id": selectedUser.id,
            "email": selectedUser.email,
            "type": "admin",
            "old_password": selectedUser.password,
            "new_password": selectedUser.new_password,
            "jwt": user.currentUser.token
        };
        console.log(data);
        fetch((`${config.api_path}/api/update_password_user.php`), {

            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(function (response) {
                if (response.status >= 400 && response.status < 500) {
                    console.log(response);
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                UIkit.notification('Datos actualizados correctamente');
                UIkit.modal(modal_name).hide();
            })
            .catch(data => {
                UIkit.notification('Error.' + data);
                console.log(data);
            })
    }
    function updateUser(modal_name) {
        const data = {
            "id": selectedUser.id,
            "firstname": selectedUser.firstname,
            "lastname": selectedUser.lastname,
            "email": selectedUser.email,
            "rol": selectedUser.rol,
            "language": selectedUser.language,
            "jwt": user.currentUser.token
        };
        console.log(data);
        fetch((`${config.api_path}/api/update_user.php`), {

            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(function (response) {
                if (response.status >= 400 && response.status < 500) {
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                UIkit.notification(text["notification_data_updated"]);
                /* const index = accountsArray.findIndex(row => row.id === selectedUser.id);
                accountsArray.splice(index, 1);
                accountsArray.push(selectedUser);
                setState({ accountsArray }); */
                window.location.reload();
                UIkit.modal(modal_name).hide();
            })
            .catch(data => {
                UIkit.notification('Error.' + data);
                console.log(data);
            })

    }
    const generateRandomPassword = () => {
        let randomstring = Math.random().toString(36).slice(-8);
        setNewUser({ ...newUser, password: randomstring });
    }
    const onClickEdit = (id) => (e) => {
        e.preventDefault();
        const selectedUserTmp = getSelectedUser(id);
        console.log(selectedUser);
        setSelectedUser(selectedUserTmp);
        UIkit.modal("#modal-edit").show();
    }
    const onClickEditPassword = (id) => (e) => {
        e.preventDefault();
        const selectedUserTmp = getSelectedUser(id);
        console.log(selectedUserTmp);
        setSelectedUser(selectedUserTmp);
        UIkit.modal("#modal-edit-password").show();
        // setState({ selectedUser: selectedUser }, () => UIkit.modal("#modal-edit-password").show());
    }
    return (
        <>
            <div className="content-before"></div>
            <h2 className="md-color-white uk-margin-top">{text["h2_title"]}</h2>
            <div data-uk-grid>
                <div className="uk-width-1-1@m" >
                    <div className="uk-card uk-card-body md-bg-white">
                        <p>{text["p_instructions"]}</p>
                        <div className='uk-overflow-auto'>
                            <table className='uk-table uk-table-hover'>
                                <thead>
                                    <tr>
                                        <th>N</th>
                                        <th>{text["table_head_name"]}</th>
                                        <th>{text["table_head_lastname"]}</th>
                                        <th>{text["table_head_email"]}</th>
                                        <th>{text["table_head_rol"]}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {accountsArray && accountsArray.map((row, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{row.firstname}</td>
                                            <td>{row.lastname}</td>
                                            <td>{row.email}</td>
                                            <td>
                                                {row.rol == 1 ? text["table_rol_user"] : text["table_rol_administrator"]}
                                            </td>
                                            <td>
                                                <i onClick={onClickEdit(`${row.id}`)} className="material-icons md-icon">edit</i>
                                                <i onClick={onClickEditPassword(`${row.id}`)} className="material-icons md-icon">vpn_key</i>
                                                <i onClick={onClickDelete(`${row.id}`)} className="material-icons md-icon">delete</i>

                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div id="modal-edit-password" data-uk-modal>
                    <div className="uk-modal-dialog uk-margin-auto-vertical">
                        <div className="uk-modal-header">
                            <h2 className="uk-modal-title">{text["modal_edit_password_title"]}</h2>
                        </div>
                        <div className="uk-modal-body">
                            <p>{selectedUser.firstname + " " + selectedUser.lastname}</p>
                            <div className="md-input-wrapper md-input-wrapper-outlined">
                                <label>{text["modal_edit_password_label_new_password"]}</label>
                                <input type="password" name='new_password' className="md-input" onChange={handleChangeEdit} required value={selectedUser.new_password} />
                            </div>
                        </div>
                        <div className="uk-modal-footer uk-text-right">
                            <button className="md-btn md-btn-text uk-modal-close ripple-surface" type="button">{text["modal_cancel"]}</button>
                            <button onClick={onClickUpdatePassword} className="md-btn md-btn-primary ripple-surface" type="button">{text["modal_update"]}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div id="modal-edit" data-uk-modal>
                    <div className="uk-modal-dialog uk-margin-auto-vertical">
                        <div className="uk-modal-header">
                            <h2 className="uk-modal-title">{text["modal_edit_user_info_title"]}</h2>
                        </div>
                        <div className="uk-modal-body">
                            <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled">
                                <label>{text["modal_edit_user_info_label_name"]}</label>
                                <input type="text" name='firstname' className="md-input" onChange={handleChangeEdit} required value={selectedUser.firstname} />
                            </div>
                            <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled">
                                <label>{text["modal_edit_user_info_label_lastname"]}</label>
                                <input type="text" name='lastname' className="md-input" onChange={handleChangeEdit} required value={selectedUser.lastname} />
                            </div>
                            <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled">
                                <label>{text["modal_edit_user_info_label_email"]}</label>
                                <input type="text" name='email' className="md-input" onChange={handleChangeEdit} required value={selectedUser.email} />
                            </div>
                            <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled">
                                <label>{text["modal_edit_user_info_label_type"]}</label>
                                <select name="rol" className="md-input" onChange={handleChangeEdit} required value={selectedUser.rol}>
                                    <option value="" disabled="" selected="" hidden></option>
                                    <option value="1" >{text["modal_edit_user_info_label_type_user"]}</option>
                                    <option value="2" >{text["modal_edit_user_info_label_type_admin"]}</option>
                                </select>
                                <span className="md-input-bar "></span>
                            </div>
                            <div>
                                <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled ">
                                    <label>{text["modal_edit_user_info_label_language"]}</label>
                                    <select name="language" className="md-input" onChange={handleChangeEdit} required value={selectedUser.language}>
                                        <option value="" disabled="" selected="" hidden></option>
                                        <option value="ES" >{text["modal_edit_user_info_label_language_es"]}</option>
                                        <option value="EN" >{text["modal_edit_user_info_label_language_en"]}</option>
                                    </select>
                                    <span className="md-input-bar "></span>
                                </div>
                            </div>

                        </div>
                        <div className="uk-modal-footer uk-text-right">
                            <button className="md-btn md-btn-text uk-modal-close ripple-surface" type="button">{text["modal_cancel"]}</button>
                            <button onClick={onClickUpdate} className="md-btn md-btn-primary ripple-surface" type="button">{text["modal_update"]}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div id="modal-new" data-uk-modal>
                    <div className="uk-modal-dialog uk-margin-auto-vertical">
                        <div className="uk-modal-header">
                            <h2 className="uk-modal-title">{text["modal_new_user_title"]}</h2>
                        </div>
                        <div className="uk-modal-body">
                            <div data-uk-grid className="uk-grid-small uk-child-width-1-1">
                                <div>
                                    <div className="md-input-wrapper md-input-wrapper-outlined">
                                        <label>{text["modal_new_user_label_name"]}</label>
                                        <input type="text" name='firstname' className="md-input" onChange={handleChange} required value={newUser.firstname} />
                                    </div>
                                </div>
                                <div>
                                    <div className="md-input-wrapper md-input-wrapper-outlined">
                                        <label>{text["modal_new_user_label_lastname"]}</label>
                                        <input type="text" name='lastname' className="md-input" onChange={handleChange} required value={newUser.lastname} />
                                    </div>
                                </div>
                                <div>
                                    <div className="md-input-wrapper md-input-wrapper-outlined">
                                        <label>{text["modal_new_user_label_email"]}</label>
                                        <input type="text" name='email' className="md-input" onChange={handleChange} required value={newUser.email} />
                                    </div>
                                </div>
                                <div>
                                    <div className="md-input-wrapper md-input-wrapper-outlined ">
                                        <label>{text["modal_new_user_label_type"]}</label>
                                        <select name="rol" className="md-input" onChange={handleChange} required value={newUser.rol}>
                                            <option value="" disabled="" selected="" hidden></option>
                                            <option value="1" >{text["modal_new_user_label_type_user"]}</option>
                                            <option value="2" >{text["modal_new_user_label_type_admin"]}</option>
                                        </select>
                                        <span className="md-input-bar "></span>
                                    </div>
                                </div>
                                <div>
                                    <div className="md-input-wrapper md-input-wrapper-outlined ">
                                        <label>{text["modal_new_user_label_language"]}</label>
                                        <select name="language" className="md-input" onChange={handleChange} required value={newUser.language}>
                                            <option value="" disabled="" selected="" hidden></option>
                                            <option value="ES" >{text["modal_new_user_label_language_es"]}</option>
                                            <option value="EN" >{text["modal_new_user_label_language_en"]}</option>
                                        </select>
                                        <span className="md-input-bar "></span>
                                    </div>
                                </div>
                                <div>
                                    <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled">
                                        <label>{text["modal_new_user_label_password"]}</label>
                                        <input type="text" name='password' className="md-input" onChange={handleChange} required value={newUser.password} />
                                    </div>
                                    <button onClick={generateRandomPassword} className="md-btn md-btn-text ripple-surface ripple-surface--primary  md-btn-primary">{text["modal_new_user_label_generate_pass"]}</button>
                                </div>
                            </div>
                        </div>
                        <div className="uk-modal-footer uk-text-right">
                            <button className="md-btn md-btn-text uk-modal-close ripple-surface" type="button">{text["modal_cancel"]}</button>
                            <button id="btn_create" onClick={onClickSignUp} className="md-btn md-color-white md-btn-primary ripple-surface">{text["modal_new_user_label_create"]}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="md-fab-wrapper">
                <a data-uk-toggle="target: #modal-new" className="md-fab md-fab-primary ripple-surface" ><i className="material-icons">add</i></a>
            </div>
        </>
    );
}
export default Home;