import React, { useEffect, useState } from 'react';
import UIkit from 'uikit';
import MDUIkit from 'md-uikit';

import EventsTable from '../../components/events_table/events_table.component';


import UserActions from '../../providers/user/user.actions';
import EventsActions from '../../providers/events/events.actions';

import { config, settings } from '../../settings.js';

const Home = () => {
    const [eventsArray, setEventArray] = useState([]);
    const [termsArray, setTermsArray] = useState([]);
    const [text, setText] = useState({});
    const [eventInfo, setEventInfo] = useState({
        status: "Inactivo"
    });

    const user = UserActions();
    const events = EventsActions();

    const handleChange = event => {
        const { value, name } = event.target;
        /* const { eventInfo } = state;
        let newObj = { ...eventInfo };
        newObj[name] = value; */
        setEventInfo({ ...eventInfo, [name]: value });
    }
    const fetchEvents = event => {
        const data = {
            "user_id": user.currentUser.Id,
            "jwt": user.currentUser.token
        };
        console.log(data);
        fetch((`${config.api_path}/api/read_event_byUserId.php`), {

            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(function (response) {
                if (response.status >= 400 && response.status < 500) {
                    UIkit.notification('No hay Eventos.');
                }
                return response.json();
            })
            .then(data => {
                //console.log(data);
                events.setEventsList(data);
                setEventArray(data);
            })
            .catch(data => {
                UIkit.notification('Error.' + data);
                console.log(data);
            })
    }
    const fetchTerms = event => {
        const data = {
            "jwt": user.currentUser.token
        };
        console.log(data);
        fetch((`${config.api_path}/api/read_terms.php`), {

            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(function (response) {
                if (response.status >= 400 && response.status < 500) {
                    UIkit.notification('No hay Eventos.');
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                setTermsArray(data);
            })
            .catch(data => {
                UIkit.notification('Error.' + data);
                console.log(data);
            })
    }
    useEffect(() => {
        setText(settings.language[user.currentUser.language]["events"]);
        fetchEvents();
        fetchTerms();
    }, []);

    const onClickCreateEvent = async event => {

        /* Validaciones */
        let flag = true;
        if (!eventInfo.title || !eventInfo.date) {
            flag = false;
        }
        if (flag) {
            const data = {
                "title": eventInfo.title,
                "description": eventInfo.description,
                "ktr": eventInfo.ktr,
                "place": eventInfo.place,
                "date": eventInfo.date,
                "status": eventInfo.status,
                "terms": eventInfo.terms,
                "jwt": user.currentUser.token,
                "user_id": user.currentUser.Id
            };
            fetch((`${config.api_path}/api/create_event.php`), {
                method: 'POST',
                body: JSON.stringify(data)
            })
                .then(function (response) {
                    if (response.status >= 400 && response.status < 500) {
                        UIkit.notification('Error.');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log(data);
                    UIkit.notification(text["alert_event_created"]);
                    UIkit.modal("#modal-new").hide();

                    window.location.reload();
                    //fetchEvents();
                })
                .catch(data => {
                    console.log(data);
                    UIkit.notification('Error.' + data);
                })
        } else {
            alert(text["alert_complete_blanks"]);
        }

    }
    return (
        <>
            <div className="content-before"></div>
            <h2 className="md-color-white uk-margin-top">{text["h2_title"]}</h2>
            <div data-uk-grid>
                <div className="uk-width-1-1@m" >
                    <div className="uk-card uk-card-body md-bg-white">
                        <p>{text["h2_subtitle"]}</p>
                        {eventsArray.length ? (
                            < EventsTable tableRowsArray={eventsArray} fetchEvents={fetchEvents} />
                        ) : (
                                <p>{text["p_loading_empty"]}</p>
                            )}
                    </div>
                </div>
            </div>
            <div>
                <div id="modal-new" data-uk-modal>
                    <div className="uk-modal-dialog uk-margin-auto-vertical">
                        <div className="uk-modal-header">
                            <h2 className="uk-modal-title">{text["h3_modal_new_title"]}</h2>
                        </div>
                        <div className="uk-modal-body uk-grid-small" data-uk-grid>
                            <div className="uk-width-1-1">
                                <div className="md-input-wrapper md-input-wrapper-outlined">
                                    <label>{text["label_modal_input_title"]}</label>
                                    <input type="text" name='title' className="md-input" onChange={handleChange} required value={eventInfo.title} />
                                </div>
                            </div>
                            <div className="uk-width-1-1">
                                <div className="md-input-wrapper md-input-wrapper-outlined md-input-textarea">
                                    <label>{text["label_modal_input_description"]}</label>
                                    <textarea className="md-input" name='description' onChange={handleChange} value={eventInfo.description}></textarea>
                                </div>
                            </div>
                            <div className="uk-width-1-1">
                                <div className="md-input-wrapper md-input-wrapper-outlined ">
                                    <label>{text["label_modal_input_ktr"]}</label>
                                    <input type="text" name='ktr' className="md-input" onChange={handleChange} required value={eventInfo.ktr} />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <div className="md-input-wrapper md-input-wrapper-outlined">
                                    <label>{text["label_modal_input_place"]}</label>
                                    <input type="text" name='place' className="md-input" onChange={handleChange} required value={eventInfo.place} />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled">
                                    <label>{text["label_modal_input_date"]}</label>
                                    <input type="date" name='date' className="md-input" onChange={handleChange} required value={eventInfo.date} />
                                </div>
                            </div>
                            <div className="uk-width-1-2@s">
                                <div className="md-input-wrapper md-input-wrapper-outlined">
                                    <label>{text["label_modal_input_status"]}</label>
                                    <select name="status" className="md-input" onChange={handleChange} required value={eventInfo.status}>
                                        <option value="Activo" >{text["label_modal_input_status_active"]}</option>
                                        <option value="Inactivo">{text["label_modal_input_status_inactive"]}</option>
                                        <option value="Pre Registro" >{text["label_modal_input_status_preregister"]}</option>
                                    </select>
                                    <span className="md-input-bar "></span>
                                </div>
                            </div>
                            <div className="uk-width-1-2@s">
                                <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled  ">
                                    <label>{text["label_event_terms"]}</label>
                                    <select name="terms" className="md-input" onChange={handleChange} required value={eventInfo.terms}>
                                        <option value disabled hidden></option>
                                        {termsArray && termsArray.map((item, index) => (
                                            <option value={item.id} >{item.name}</option>
                                        ))}
                                    </select>
                                    <span className="md-input-bar "></span>
                                </div>
                            </div>
                        </div>
                        <div className="uk-modal-footer uk-text-right">
                            <button className="md-btn md-btn-text uk-modal-close" type="button">{text["modal_cancel"]}</button>
                            <button onClick={onClickCreateEvent} className="md-btn md-color-white md-btn-primary">{text["button_modal_create_event"]}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="md-fab-wrapper">
                <a data-uk-toggle="target: #modal-new" className="md-fab md-fab-primary ripple-surface"><i className="material-icons">add</i></a>
            </div>
        </>
    );
}
export default Home;