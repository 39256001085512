import React, { createContext, useEffect, useReducer } from 'react';

import eventsReducer, { INITIAL_STATE } from "./events.reducer";

export const EventsContext = createContext();

const Events = ({ children }) => {
    const [state, dispatch] = useReducer(eventsReducer, INITIAL_STATE, (INITIAL_STATE) => {
        const localData = sessionStorage.getItem("Events");
        return localData ? JSON.parse(localData) : INITIAL_STATE
    });
    useEffect(() => {
        sessionStorage.setItem('Events', JSON.stringify(state));
    }, [state])

    return (
        <EventsContext.Provider value={{ state, dispatch }}>
            {children}
        </EventsContext.Provider>
    )
}
export default Events;