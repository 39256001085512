
export const INITIAL_STATE = {
    eventsArray: []
}
const serviceReducer = (state, action) => {
    switch (action.type) {
        case "SET_EVENTS_LIST":
            return {
                ...state,
                eventsArray: action.payload
            };
        
        default:
            return state;
    }
}
export default serviceReducer;