import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import UIkit from 'uikit';
import MDUIkit from 'md-uikit'

import UserActions from '../../providers/user/user.actions';
import { config, settings } from '../../settings.js';

const Account = () => {

    const [infoUser, setInfoUser] = useState([]);
    const [passwordData, setPasswordData] = useState({
        old_password: "",
        new_password: "",
        renew_password: "",
    });

    const [languages] = useState([{
        id: "ES",
        title: "Español"
    }, {
        id: "EN",
        title: "English"
    }]);
    const user = UserActions();
    const [text, setText] = useState({});

    const handlePassword = event => {
        const { value, name } = event.target;
        setPasswordData({ ...passwordData, [name]: value });
    }
    const handleChange = event => {
        const { value, name } = event.target;
        setInfoUser({ ...infoUser, [name]: value });
    }
    const updateLang = event => {
        const data = {
            "id": infoUser.Id,
            "email": infoUser.email,
            "language": infoUser.language,
            "jwt": user.currentUser.token
        };
        console.log(data);
        fetch((`${config.api_path}/api/update_user.php`), {

            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(function (response) {
                if (response.status >= 400 && response.status < 500) {
                    console.log("error 400");
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                user.setCurrentUser({ ...user.currentUser, language: infoUser.language })
               // user.setLanguageData(settings.language[user.currentUser.language]);
                UIkit.modal('#modal-lang').hide();

            })
            .catch(data => {
                UIkit.notification('Error.' + data);
                console.log(data);
            })
    }
    const updateUserPassword = event => {
        let flag = true;
        if (passwordData.new_password !== "" && passwordData.renew_password == passwordData.new_password) {
            const data = {
                "id": infoUser.Id,
                "email": infoUser.email,
                "old_password": passwordData.old_password,
                "new_password": passwordData.new_password,
                "jwt": user.currentUser.token
            };
            console.log(data);
            fetch((`${config.api_path}/api/update_password_user.php`), {

                method: 'POST',
                body: JSON.stringify(data)
            })
                .then(function (response) {
                    if (response.status >= 400 && response.status < 500) {
                        alert(text["notification_wrong_password"]);
                        flag = false;
                    }else{
                        return response.json();
                    }
                })
                .then(data => {
                    if (flag) {
                        console.log(data);
                        UIkit.notification(text["notification_data_updated"]);
                        setPasswordData({
                            old_password: "",
                            new_password: "",
                            renew_password: "",
                        });
                        UIkit.modal('#modal-edit').hide();
                    }

                })
                .catch(data => {
                    UIkit.notification('Error.' + data);
                    console.log(data);
                })
        } else {
            alert(text["notification_different_password"]);
        }
    }
    useEffect(() => {
        MDUIkit.components();
        setInfoUser(user.currentUser);
        setText(settings.language[user.currentUser.language]["account"]);
    }, []);
    useEffect(() => {
        setText(settings.language[user.currentUser.language]["account"]);
    }, [user.currentUser]);
    const cancelEdit = event => {
        setPasswordData({
            old_password: "",
            new_password: "",
            renew_password: "",
        });
    }
    const handleChangeRadio = value => event => {
        /* const { value } = event.target; */
        console.log(value);
        setInfoUser({ ...infoUser, language: value })
    }
    console.log("text");
    console.log(text);
    return (
        <>
            <div className="content-before"></div>
            <h2 className="md-color-white">{text["h2.title"]}</h2>
            <div data-uk-grid>
                <div className="uk-width-1-1" >
                    <div className="uk-card uk-card-body md-bg-white">
                        <h2 align="center">{user.currentUser.FirstName} {user.currentUser.LastName}</h2>
                        <p>{text["p.subtitle"]}</p>
                        <ul className="uk-list uk-list-divider">
                            <li className="ripple-surface pointer" data-uk-toggle="target: #modal-edit">
                                <div className="uk-flex uk-flex-between">
                                    <div>
                                        <h5 className="uk-margin-remove-bottom">{text["h5_password_option_title"]}</h5>
                                        <span className="uk-text-small uk-text-muted">{text["h5_password_option_subtitle"]}</span>
                                    </div>
                                </div>
                            </li>
                            <li className="ripple-surface pointer" data-uk-toggle="target: #modal-lang">
                                <div className="uk-flex uk-flex-between">
                                    <div>
                                        <h5 className="uk-margin-remove-bottom">{text["h5_language_option_title"]}</h5>
                                        <span className="uk-text-small uk-text-muted">{text["h5_language_option_subtitle"]} ({infoUser.language})</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
                <div id="modal-edit" data-uk-modal>
                    <div className="uk-modal-dialog uk-margin-auto-vertical">
                        <div className="uk-modal-header">
                            <h2 className="uk-modal-title">{text["h2_edit_password_title"]}</h2>
                        </div>
                        <div className="uk-modal-body uk-grid-small" data-uk-grid>

                            <div className="uk-width-1-1">
                                <div className="md-input-wrapper md-input-wrapper-outlined">
                                    <label>{text["label_edit_password_oldpassword"]}</label>
                                    <input type="password" name='old_password' className="md-input" onChange={handlePassword} required value={passwordData.old_password} />
                                </div>
                            </div>
                            <div className="uk-width-1-1">
                                <div className="md-input-wrapper md-input-wrapper-outlined">
                                    <label>{text["label_edit_password_newpassword"]}</label>
                                    <input type="text" name='new_password' className="md-input" onChange={handlePassword} required value={passwordData.new_password} />
                                </div>
                            </div>
                            <div className="uk-width-1-1">
                                <div className="md-input-wrapper md-input-wrapper-outlined">
                                    <label>{text["label_edit_password_renewpassword"]}</label>
                                    <input type="text" name='renew_password' className="md-input" onChange={handlePassword} required value={passwordData.renew_password} />
                                </div>
                            </div>
                        </div>
                        <div className="uk-modal-footer uk-text-right">
                            <button onClick={cancelEdit} className="md-btn md-btn-text uk-modal-close ripple-surface" type="button">{text["modal_cancel"]}</button>
                            <button onClick={updateUserPassword} className="md-btn md-color-white md-btn-primary ripple-surface">{text["modal_update"]}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div id="modal-lang" data-uk-modal>
                    <div className="uk-modal-dialog uk-margin-auto-vertical">
                        <div className="uk-modal-header">
                            <h2 className="uk-modal-title">{text["h2_edit_language_title"]}</h2>
                        </div>
                        <div className="uk-modal-body">
                            <ul className="uk-list uk-list-divider">
                                {languages.map((item, index) => (
                                    <li key={index} className="ripple-surface pointer" onClick={handleChangeRadio(item.id)}>
                                        <div className="uk-flex uk-flex-between uk-flex-middle">
                                            <div>
                                                <h5 className="uk-margin-remove-bottom">{item.title}</h5>
                                            </div>
                                            <div className="md-radio md-radio-inline">
                                                <input id={`radio_${item.id}`} type="radio" name="playlistItem" checked={infoUser.language === item.id} value={item.id} />
                                                <label htmlFor={`radio_${item.id}`}></label>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="uk-modal-footer uk-text-right">
                            <button onClick={cancelEdit} className="md-btn md-btn-text uk-modal-close ripple-surface" type="button">{text["modal_cancel"]}</button>
                            <button onClick={updateLang} className="md-btn md-color-white md-btn-primary ripple-surface">{text["modal_update"]}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Account;