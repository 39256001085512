import React, { useEffect } from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import MDUIkit from 'md-uikit';

import LoginPage from './views/portal/LoginPage.js';
import SignUpPage from './views/portal/SignUpPage.js';
/* import SignUpComplete from './views/portal/SignUpPageComplete.js'; */
import verifyEmail from './views/portal/verifyEmail.js';
import AdminPanel from './views/portal/base/AdminPanel.js';
import SignUpForm from './views/form/SignUpForm.js';
import NotFound from './views/NotFound.js';


import UserActions from './providers/user/user.actions';

import 'uikit/dist/css/uikit.min.css';
import 'md-uikit/dist/css/md-uikit.css';
import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';//,faDownload,faLinkedIn
//import { faDownload} from '@fortawesome/free-solid-svg-icons';
library.add(fab);

function App() {

  const user = UserActions();
  useEffect(() => {
    MDUIkit.components();
    /* user.setCurrentUser({ rol: 1 }); */
    console.log();
  }, [])
  return (
    <div className="App">
      <Router>
        <Switch>
          {/* <Route exact path='/signupcomplete' component={SignUpComplete} /> */}
          <Route path='/' exact render={() =>
            user.currentUser ? (
              <Redirect to={user.currentUser.rol == 1 ? '/user/events' : '/dashboard/events'} />
            ) : (
                <LoginPage />
              )
          } />
          <Route path='/dashboard' render={() =>
            user.currentUser ? (
              <AdminPanel />
            ) : (
                <Redirect to='/login' />
              )
          } />
          <Route path='/user' render={() =>
            user.currentUser ? (
              <AdminPanel />
            ) : (
                <Redirect to='/login' />
              )
          } />
          <Route exact path='/signup' component={SignUpPage} />
          <Route exact path='/verifyemail/:token' component={verifyEmail} />
          <Route exact path='/form/:lang/:codeParam' component={SignUpForm} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </div>
  )
}
export default App;
