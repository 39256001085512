import React, { useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router';

import 'uikit/dist/css/uikit.min.css';
import UIkit from 'uikit/dist/js/uikit.js';
import MDUIkit from 'md-uikit';
import Logo from "../../assets/img/logo_sign.png";
import BGLogin from "../../assets/img/login_bg.jpg";

import UserActions from '../../providers/user/user.actions';
import { config } from '../../settings.js';

const LoginPage = () => {
    const [userCredentials, setUserCredentials] = useState({ email: "", password: "" });
    const [isLogged, setIsLogged] = useState(false);
    let history = useHistory();
    const user = UserActions();
    const { email, password } = userCredentials;
    const onClickLogin = async event => {

        /* Validaciones */
        let flag = true;
        if (email === "") {
            flag = false;
        }
        if (password === "") {
            flag = false;
        }
        const button = document.querySelector("#btn_login");
        button.innerHTML = '<div data-uk-spinner></div> Cargando...';
        button.disabled = true;
        if (flag) {
            try {
                const data = {
                    "email": email,
                    "password": password,
                    "type": "email"
                };
                console.log("Login: ");
                console.log(data);
                const response = await fetch(`${config.api_path}/api/login.php`, {
                    method: 'POST',
                    body: JSON.stringify(data)
                });
                if (response.status >= 400 && response.status < 500) {
                    UIkit.notification('Usuario y/o contraseña incorrectos.');
                    throw "Error" + response.status;
                }
                if (response.status >= 500 && response.status < 600) {
                    UIkit.notification('Error. Please try later.');
                }
                const res = await response.json();
                console.log(res);
                if (res.user) {
                    user.setCurrentUser({
                        'Id': res.user.id,
                        'Name': res.user.firstname + ' ' + res.user.lastname,
                        'FirstName': res.user.firstname,
                        'LastName': res.user.lastname,
                        'email': res.user.email,
                        'rol': res.user.rol,
                        'language': res.user.language,
                        'token': res.token
                    })
                }
                button.innerHTML = 'Iniciar Sesion';
                button.disabled = false;
            } catch (error) {
                button.innerHTML = 'Iniciar Sesion';
                button.disabled = false;
                console.log(error);
            }
        } else {
            UIkit.notification('Please fill blank spaces');
        }

    }
    useEffect(() => {
        MDUIkit.components();
    }, [])
    const handleChange = event => {
        const { value, name } = event.target;
        setUserCredentials({ ...userCredentials, [name]: value })
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            onClickLogin(event)
        }
    }
    if (isLogged) {
        return <Redirect push to="/admin" />;
    }
    return (
        <div data-uk-height-viewport data-uk-grid>
            <div className="uk-width-1-2@s uk-visible@s" style={{ backgroundImage: `url(${BGLogin})`, backgroundSize: "cover" }}> </div>
            <div className="uk-width-1-2@s md-bg-white">
                <div className="uk-padding">
                    <div className="uk-grid-small" data-uk-grid>
                        <div className="uk-width-1-1">
                            <img className="uk-align-center" src={Logo} width='80%' alt='' />
                        </div>
                        <div className="uk-width-1-1">
                            <div className="md-input-wrapper">
                                <label>User:</label>
                                <input name="email" type="text" className="md-input" onChange={handleChange} required value={email} />
                                <span className="md-input-bar"></span>
                            </div>
                        </div>
                        <div className="uk-width-1-1">
                            <div className="md-input-wrapper">
                                <label>Password:</label>
                                <input name="password" type="password" className="md-input" onKeyPress={handleKeyPress} onChange={handleChange} required value={password} />
                                <span className="md-input-bar"></span>
                            </div>
                        </div>
                        <div className="uk-margin-medium-top uk-width-1-1">
                            <button onClick={onClickLogin} id="btn_login" className="md-btn md-color-white md-btn-primary md-btn-block ripple-surface">Login</button>

                            {/* <p>New to PTE? <Link to={'./signup'}>Sign up</Link></p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LoginPage;