import React, { useEffect, useState } from 'react';
import MDUIkit from 'md-uikit';
import { Link } from "react-router-dom";
/* import 'md-uikit/dist/css/md-uikit.css'; */

import UserActions from '../../providers/user/user.actions';
import EventsActions from '../../providers/events/events.actions';

import { config, settings } from '../../settings.js';

const SideBar = ({participantsArray}) => {

    const [text, setText] = useState({});
    const events = EventsActions();
    const user = UserActions();

    useEffect(() => {
        let textData = settings.language[user.currentUser.language]["events_detail_table"];
        setText(textData);
        MDUIkit.components();
    }, []);
    return (
        <div className='uk-overflow-auto'>
            <table className='uk-table uk-table-hover'>
                <thead>
                    <tr>
                        <th>N.</th>
                        <th>{text["table_head_name"]}</th>
                        <th>{text["table_head_email"]}</th>
                        <th>{text["table_head_status"]}</th>
                        <th>{text["table_head_date"]}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {participantsArray && participantsArray.map((row, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{row.firstname + " " + row.lastname}</td>
                            <td>{row.email}</td>
                            <td>
                                {row.verified_bool == true ? "Complete" : "Pending"}
                            </td>
                            <td>{row.date}</td>
                            <td>
                                {/* {row.verified_bool == true ? <img src={`${config.api_path}/files/signature_${row.id}.png?du=${Math.random()}`} alt="signature" />
                                        : "-"} */}
                                {row.verified_bool == true ? <a href={`${config.api_path}/reports/GenerarFichadeDatosIndividual.php?Id=${row.id}`} className='md-btn md-color-white md-bg-red-900'>PDF</a>
                                    : "-"}
                            </td>
                        </tr>
                    ))}

                </tbody>
            </table>
        </div>
    )
}
export default SideBar;