import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import 'uikit/dist/css/uikit.min.css';
import UIkit from 'uikit/dist/js/uikit.js';
import MDUIkit from 'md-uikit';
import Logo from "../../assets/img/logo_sign.png";
import BGLogin from "../../assets/img/bg_login.jpg";
import { connect } from 'react-redux';
import { setCurrentUser } from '../../redux/user/user.actions';


class SignUpComplete extends Component {
    componentDidMount() {
        MDUIkit.components();
        const { token } = this.props.match.params
    }
    handleChange = event => {
    }
    render() {
        return (
            <div data-uk-height-viewport className="uk-padding" style={{ backgroundImage: `url(${BGLogin})` }} data-uk-grid>
                <div className="uk-width-1-3@s"></div>
                <div className="uk-width-1-3@s">
                    <div className="md-card">
                        <div className="md-card-content">
                            <img className="uk-align-center" src={Logo} width="80%" alt='' />
                            <h5>Your account was successfully verified.</h5>
                            <div className="uk-margin-medium-top">
                                <Link to={"./login"} className="md-btn md-color-white md-btn-primary md-btn-block md-btn-large">LOGIN</Link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SignUpComplete;