
import UserProvider from "./user/user.provider";
import EventsProvider from "./events/events.provider";
import React from 'react';

const Provider = ({ children }) => {
    return (

        <UserProvider>
            <EventsProvider>
                {children}
            </EventsProvider>
        </UserProvider>
    );
}
export default Provider;