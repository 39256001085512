export const config =
{
    api_path: "https://fnfrapifirm.com/backend",
    main_url: "https://fnfrapifirm.com"
    /* api_path: "http://localhost/rapifirm",
    main_url: "http://localhost:3000", */

}

export const settings = {
    language: {
        ES: {
            routes: {
                "label_events": "Eventos",
                "label_account": "Cuenta",
                "label_account_admin": "Usuarios",
            },
            account: {
                "modal_cancel": "Cancelar",
                "modal_update": "Actualizar",
                "notification_data_updated": "Datos actualizados",
                "h2.title": "Cuenta",
                "p.subtitle": "Porfavor seleccione una opción:",
                "h5_password_option_title": "Contraseña",
                "h5_password_option_subtitle": "Haga click aquí para actualizar una contraseña",
                "h5_language_option_title": "Idioma",
                "h5_language_option_subtitle": "Haga click aquí para seleccionar un idioma",
                "h2_edit_password_title": "Actualizar Contraseña",
                "label_edit_password_oldpassword": "Contraseña anterior",
                "label_edit_password_newpassword": "Nueva contraseña",
                "label_edit_password_renewpassword": "Repetir nueva contraseña",
                "h2_edit_language_title": "Actualizar Idioma",
                "notification_different_password": "La contraseña nueva es diferente",
                "notification_wrong_password": "Contraseña incorrecta"
            },
            events: {
                "h2_title": "Eventos",
                "modal_cancel": "Cancelar",
                "modal_update": "Actualizar",
                "h2_subtitle": "Presione el botón (+) para crear un nuevo evento.",
                "p_loading_empty": "No hay datos para mostrar",
                "h3_modal_new_title": "Nuevo Evento",
                "label_modal_input_title": "Titulo",
                "label_modal_input_description": "Descripción",
                "label_modal_input_ktr": "KTR",
                "label_modal_input_place": "Lugar",
                "label_modal_input_date": "Fecha",
                "label_modal_input_status": "Estado",
                "label_modal_input_status_active": "Activo",
                "label_modal_input_status_inactive": "Inactivo",
                "label_modal_input_status_preregister": "Pre Registro",
                "button_modal_create_event": "Crear Evento",
                "alert_event_created": "Evento creado",
                "alert_complete_blanks": "Porfavor, complete los espacios en blanco",
                "label_event_terms": "Términos y Condiciones"
            },
            events_detail: {
                "modal_cancel": "Cancelar",
                "modal_update": "Actualizar",
                "h2_title": "Información del Evento",
                "label_event_title": "Título",
                "label_event_description": "Descripción",
                "label_event_place": "Lugar",
                "label_event_ktr": "KTR",
                "label_event_date": "Fecha",
                "label_event_status": "Estado",
                "label_event_status_active": "Activo",
                "label_event_status_inactive": "Inactivo",
                "label_event_status_preregister": "Pre Registro",
                "label_event_terms": "Términos y Condiciones",
                "export_to_excel": "Exportar Lista a excel",
                "export_to_excel_opal": "Exportar OPAL",
                "export_to_pdf": "Exportar Lista a PDF",
                "export_share": "Compartir",
                "label_update_info": "Actualizar Información",
                "text_close": "Cerrar",
                "h2_share_event_title": "Compartir Evento",
                "notification_no_people": "No hay participantes",
                "notification_event_updated": "Evento actualizado",
            },
            events_table: {
                "table_th_date": "Fecha",
                "table_th_title": "Título",
                "table_th_codigo": "Código",
                "table_th_participants": "Participantes",
                "table_th_status": "Estado",
                "label_table_search": "Buscar",
                "h3_modal_share_title": "Compartir",
                "h3_modal_share_close": "Cerrar",
                "label_table_share_option": "Compartir",
                "label_table_delete_option": "Eliminar",
            },
            events_detail_table: {
                "table_head_name": "Nombre Completo",
                "table_head_email": "Email",
                "table_head_status": "Estado",
                "table_head_date": "Fecha"
            },
            signup_form: {
                "p_mandatory": "* Campos obligatorios",
                "label_email": "Email",
                "label_name": "Nombres",
                "label_lastname": "Apellidos",
                "label_phone": "Celular",
                "label_institution": "Institución",
                "label_address": "Dirección",
                "label_country": "Pais",
                "label_sex": "Sexo",
                "label_sex_m": "Masculino",
                "label_sex_f": "Femenino",
                "label_sex_n": "Otro",
                "label_city": "Ciudad",
                "label_signature": "Firma",
                "label_signature_clear": "Borrar Firma",
                "label_send_info": "Enviar información",
                "label_terms_and_conditions": "Términos y Condiciones",
                "label_accent_terms": "Acepto los | para este evento.",
                "label_event_not_available_title": "Lo sentimos",
                "label_event_not_available_subtitle": "Este evento no esta disponible",
                "label_event_welcome": "Bienvenido!",
                "label_event_welcome_subtitle": "Porfavor ingrese un codigo de evento valido.",
                "label_event_welcome_code": "Codigo del evento",
                "final_message_title": "Tu informacion ha sido enviada correctamente",
                "final_message_subtitle": "Puedes cerrar esta ventana"
            },
            users_admin: {
                "h2_title": "Administrador de Usuarios",
                "p_instructions": "Presione (+) para crear un nuevo usuario.",
                "table_head_name": "Nombres",
                "table_head_lastname": "Apellidos",
                "table_head_email": "Usuario",
                "table_head_rol": "Rol",
                "table_rol_user": "Usuario",
                "table_rol_administrator": "Administrador",
                "modal_edit_password_title": "Actualizar Contraseña",
                "modal_edit_password_label_new_password": "Escriba una nueva contraseña",
                "modal_cancel": "Cancelar",
                "modal_update": "Actualizar",
                "modal_edit_user_info_title": "Actualizar Usuario",
                "modal_edit_user_info_label_name": "Nombres",
                "modal_edit_user_info_label_lastname": "Apellidos",
                "modal_edit_user_info_label_email": "Usuario",
                "modal_edit_user_info_label_type": "Tipo de Usuario",
                "modal_edit_user_info_label_type_user": "Usuario",
                "modal_edit_user_info_label_type_admin": "Administrador",
                "modal_edit_user_info_label_language": "Idioma",
                "modal_edit_user_info_label_language_es": "Español",
                "modal_edit_user_info_label_language_en": "English",
                "modal_new_user_title": "Nuevo usuario",
                "modal_new_user_label_name": "Nombres",
                "modal_new_user_label_lastname": "Apellidos",
                "modal_new_user_label_email": "Usuario",
                "modal_new_user_label_type": "Tipo de Usuario",
                "modal_new_user_label_type_user": "Usuario",
                "modal_new_user_label_type_admin": "Administrador",
                "modal_new_user_label_language": "Idioma",
                "modal_new_user_label_language_es": "Español",
                "modal_new_user_label_language_en": "English",
                "modal_new_user_label_password": "Contraseña",
                "modal_new_user_label_generate_pass": "Generar Contraseña",
                "modal_new_user_label_create": "Crear usuario",
                "notification_data_updated": "Los datos fueron actualizados correctamente",
                "notification_write_password": "Porfavor escriba una contraseña",
                "notification_user_deleted": "Usuario eliminado",
                "notification_white_spaces": "Por favor, complete los espacios en blanco",
                "notification_email_registered": "Este email ya esta registrado",
                "notification_confirm_deleting": "Estas seguro?"
            },
            header: {
                "label_logout": "Cerrar Sesión"
            },
            reports: {
                "h2.title": "Reportes",
                "export_to_excel": "Exportar a Excel",
                "search_type": "Tipo de reporte",
                "search_from": "Desde:",
                "search_to": "Hasta:",
                "search_text": "Buscar",
                "search_option_att_per_event": "Asistentes por evento",
                "report_date" : "Fecha",
                "report_title": "Título",
                "report_email": "Email",
                "report_firstname": "Nombres",
                "report_lastname": "Apellidos",
                "report_phone": "Teléfono",
                "report_city": "Ciudad",
                "report_country": "País",
                "report_institution": "Institución",
                "search_results": "Resultados de búsqueda",
                "loading" : "Cargando..."
            }
        },
        EN: {
            account: {
                "modal_cancel": "Cancel",
                "modal_update": "Update",
                "notification_data_updated": "Data updated!",
                "h2.title": "Account",
                "p.subtitle": "Please select an option:",
                "h5_password_option_title": "Password",
                "h5_password_option_subtitle": "Click here to set a new password",
                "h5_language_option_title": "Language",
                "h5_language_option_subtitle": "Click here to choose a language",
                "h2_edit_password_title": "Update Password",
                "label_edit_password_oldpassword": "Current Password",
                "label_edit_password_newpassword": "New Password",
                "label_edit_password_renewpassword": "Repeat New Password",
                "h2_edit_language_title": "Update Language",
                "notification_different_password": "The new password does not match.",
                "notification_wrong_password": "Wrong password"
            },
            events: {
                "h2_title": "Events",
                "modal_cancel": "Cancel",
                "modal_update": "Update",
                "h2_subtitle": "Press (+) button to create a new event.",
                "p_loading_empty": "No data to show",
                "h3_modal_new_title": "New Event",
                "label_modal_input_title": "Title",
                "label_modal_input_description": "Description",
                "label_modal_input_ktr": "KTR",
                "label_modal_input_place": "Place",
                "label_modal_input_date": "Date",
                "label_modal_input_status": "Status",
                "label_modal_input_status_active": "Active",
                "label_modal_input_status_inactive": "Inactive",
                "label_modal_input_status_preregister": "Pre-registration",
                "button_modal_create_event": "Create Event",
                "alert_event_created": "Event created",
                "alert_complete_blanks": "Please complete whitespaces",
                "label_event_terms": "Terms and Conditions",
            },
            events_detail: {
                "modal_cancel": "Cancel",
                "modal_update": "Update",
                "h2_title": "Event Information",
                "label_event_title": "Title",
                "label_event_description": "Description",
                "label_event_place": "Place",
                "label_event_ktr": "KTR",
                "label_event_date": "Date",
                "label_event_status": "Status",
                "label_event_status_active": "Active",
                "label_event_status_inactive": "Inactive",
                "label_event_status_preregister": "Pre-registration",
                "label_event_terms": "Terms and Conditions",
                "export_to_excel": "Export List to excel",
                "export_to_excel_opal": "Export to OPAL",
                "export_to_pdf": "Export List to PDF",
                "export_share": "Share",
                "label_update_info": "Update Information",
                "text_close": "Close",
                "h2_share_event_title": "Share Event",
                "notification_no_people": "There is no participants",
                "notification_event_updated": "Event updated",
            },
            events_table: {
                "table_th_date": "Date",
                "table_th_title": "Title",
                "table_th_codigo": "Code",
                "table_th_participants": "Participants",
                "table_th_status": "Status",
                "label_table_search": "Search",
                "h3_modal_share_title": "Share",
                "h3_modal_share_close": "Close",
                "label_table_share_option": "Share",
                "label_table_delete_option": "Delete",
            },
            events_detail_table: {
                "table_head_name": "Full Name",
                "table_head_email": "Email",
                "table_head_status": "Status",
                "table_head_date": "Date"
            },
            signup_form: {
                "p_mandatory": "* Mandatory field",
                "label_email": "Email",
                "label_name": "Name",
                "label_lastname": "Last Name",
                "label_phone": "Phone",
                "label_institution": "Institution",
                "label_address": "Address",
                "label_country": "Country",
                "label_sex": "Gender",
                "label_sex_m": "Male",
                "label_sex_f": "Female",
                "label_sex_n": "Other",
                "label_city": "City",
                "label_signature": "Signature",
                "label_signature_clear": "Clear Signature",
                "label_send_info": "Send Information",
                "label_terms_and_conditions": "Terms and Conditions",
                "label_accent_terms": "I accept the | for this event.",
                "label_event_not_available_title": "Sorry",
                "label_event_not_available_subtitle": "This event is not available",
                "label_event_welcome": "Welcome!",
                "label_event_welcome_subtitle": "Please enter a valid event code to continue",
                "label_event_welcome_code": "Event code",
                "final_message_title": "Your information has been sent successfully",
                "final_message_subtitle": "You can close this window"
            },
            users_admin: {
                "h2_title": "Users Administrator",
                "p_instructions": "Press (+) to create a new user.",
                "table_head_name": "Name",
                "table_head_lastname": "Last Name",
                "table_head_email": "User",
                "table_head_rol": "Rol",
                "table_rol_user": "User",
                "table_rol_administrator": "Administrator",
                "modal_edit_password_title": "Update Password",
                "modal_edit_password_label_new_password": "Enter a new password",
                "modal_cancel": "Cancel",
                "modal_update": "Update",
                "modal_edit_user_info_title": "Update User",
                "modal_edit_user_info_label_name": "Name",
                "modal_edit_user_info_label_lastname": "Last Name",
                "modal_edit_user_info_label_email": "User",
                "modal_edit_user_info_label_type": "User Type",
                "modal_edit_user_info_label_type_user": "User",
                "modal_edit_user_info_label_type_admin": "Administrator",
                "modal_edit_user_info_label_language": "language",
                "modal_edit_user_info_label_language_es": "Español",
                "modal_edit_user_info_label_language_en": "English",
                "modal_new_user_title": "New user",
                "modal_new_user_label_name": "Name",
                "modal_new_user_label_lastname": "Last Name",
                "modal_new_user_label_email": "User",
                "modal_new_user_label_type": "User Type",
                "modal_new_user_label_type_user": "User",
                "modal_new_user_label_type_admin": "Administrator",
                "modal_new_user_label_language": "Language",
                "modal_new_user_label_language_es": "Español",
                "modal_new_user_label_language_en": "English",
                "modal_new_user_label_password": "Password",
                "modal_new_user_label_generate_pass": "Generate Password",
                "modal_new_user_label_create": "Create user",
                "notification_data_updated": "User updated!",
                "notification_write_password": "Please, enter a password",
                "notification_user_deleted": "Deleted user",
                "notification_white_spaces": "Please, complete whitespaces",
                "notification_email_registered": "This email is already registered",
                "notification_confirm_deleting": "Are you sure?"
            },
            header: {
                "label_logout": "Logout",
            },
            reports: {
                "h2.title": "Reports",
                "export_to_excel": "Export to Excel",
                "search_type": "Report type",
                "search_from": "From:",
                "search_to": "To:",
                "search_text": "Search",
                "search_option_att_per_event": "Attendees per event",
                "report_date" : "Date",
                "report_title": "Title",
                "report_email": "Email",
                "report_firstname": "First Name",
                "report_lastname": "Last Name",
                "report_phone": "Phone",
                "report_city": "City",
                "report_country": "Country",
                "report_institution": "Company",
                "search_results": "Search results",
                "loading" : "Loading..."
            }
        }
    }
}