import HomePageAdmin from "../HomePageAdmin.js";
import UsersAdmin from "../UsersAdmin.js";
import Account from "../Account.js";
import Reports from "../Reports.js";

const Routes = [
  {
    name: "Events",
    path: "/dashboard/events",
    icon: 'description',
    component: HomePageAdmin,
    rol: '2'
  },
  
  {
    name: "Events",
    path: "/user/events",
    icon: 'description',
    component: HomePageAdmin,
    rol: '1'
  },
  {
    name: "Reports",
    path: "/dashboard/reports",
    icon: 'assessment',
    component: Reports,
    rol: '2'
  },
  {
    name: "Reports",
    path: "/user/reports",
    icon: 'assessment',
    component: Reports,
    rol: '1'
  },
  {
    name: "Account",
    path: "/dashboard/account",
    icon: 'person',
    component: Account,
    rol: '2'
  },
  {
    name: "Account",
    path: "/user/account",
    icon: 'person',
    component: Account,
    rol: '1'
  },
  {
    name: "Users",
    path: "/dashboard/users",
    icon: 'vpn_key',
    component: UsersAdmin,
    rol: '2'
  }
];

export default Routes;
