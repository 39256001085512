import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux'
import { Link } from "react-router-dom";

import UIkit from 'uikit';
import QRCode from "qrcode.react"
import { config } from '../../settings.js';

import MDUIkit from 'md-uikit';
import 'md-uikit/dist/css/md-uikit.css';

import UserActions from '../../providers/user/user.actions';

import { settings } from '../../settings.js';
const Table = ({ tableRowsArray, fetchEvents }) => {
    const [text, setText] = useState({});
    const [tableHeaders, setTableHeaders] = useState()
    const [tableRows, setTableRows] = useState([]);
    const [currentEvent, setCurrentEvent] = useState([]);
    const [order, setOrder] = useState([]);
    const user = UserActions();
    useEffect(() => {
        let textData = settings.language[user.currentUser.language]["events_table"];
        setText(textData);
        setTableHeaders([
            { title: textData["table_th_date"], data: "date" },
            { title: textData["table_th_title"], data: "title" },
            { title: textData["table_th_codigo"], data: "code" },
            { title: textData["table_th_participants"], data: "num_of_users" },
            { title: textData["table_th_status"], data: "status" }
        ])
        console.log(text);
        MDUIkit.components();
    }, [])
    useEffect(() => {
        setTableRows(tableRowsArray);
    }, [tableRowsArray])
    const onClickShare = (id) => (e) => {
        const selectedEvent = tableRows.find(event => event.id === id);
        setCurrentEvent(selectedEvent)
        UIkit.modal("#modal-share").show();
    }

    const onClickDelete = (id) => (e) => {
        e.preventDefault();
        if (window.confirm('Are you sure?')) {
            const data = {
                "id": id,
                "jwt": user.currentUser.token
            };
            fetch((`${config.api_path}/api/delete_event.php`), {
                method: 'POST',
                body: JSON.stringify(data)
            })
                .then(function (response) {
                    if (response.status >= 400 && response.status < 500) {
                        UIkit.notification('Error.');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log(data);
                    UIkit.notification('Event deleted.');
                    fetchEvents();
                })
                .catch(data => {
                    console.log(data);
                    UIkit.notification('Error.' + data);
                })
        }
    }
    const orderBy = (label) => event => {
        event.preventDefault();
        const temp_order = { ...order }
        let data_title = tableHeaders.find(item => item.title == label);
        console.log('order by ' + label + ' founded ' + data_title);
        let newObj;
        if (temp_order.label == label) {
            if (temp_order.type == "ASC") {
                newObj = tableRows.sort(compareValues(data_title.data, 'desc'));
                temp_order.type = "DESC";
            } else {
                newObj = tableRows.sort(compareValues(data_title.data));
                temp_order.type = "ASC";
            }
        } else {
            newObj = tableRows.sort(compareValues(data_title.data));
            temp_order.label = label;
        }

        setTableRows(newObj);
        setOrder(temp_order);
    }
    const filterTable = event => {
        const { value } = event.target;
        let filteredData = [...tableRowsArray];
        if (value) {
            for (let j = 0; j < tableRowsArray.length; j++) {
                const element = tableRowsArray[j];
                let bool_exist = false;
                for (let index = 0; index < tableHeaders.length; index++) {
                    const element_inner = tableHeaders[index];
                    if (element[element_inner.data].toLowerCase().includes(value.toLowerCase())) {
                        console.log(element[element_inner.data]);
                        bool_exist = true;
                    }
                }
                if (!bool_exist) {
                    console.log("eliminado " + element.title);
                    filteredData = filteredData.filter(item => item.id !== element.id);
                }
                /* let temp = tableRows.filter(row =>
                    row[element.data].toLowerCase().includes(value.toLowerCase())
                );
                filteredData = filteredData.concat(temp); */
            }
        }
        else {
            filteredData = tableRowsArray;
            console.log('else')
        }
        console.log(filteredData)
        setTableRows(filteredData);
    }
    function compareValues(key, order = 'asc') {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }
            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }
    return (
        <>
            <div className="uk-grid-small" data-uk-grid>
                <div className="uk-width-1-2@s">
                </div>
                <div className="uk-width-1-2@s">
                    <div className="md-input-wrapper md-input-wrapper-shaped md-input-wrapper-with-trailing-icon md-input-wrapper-outlined">
                        <span className="md-input-icon"><i className="material-icons">search</i></span>
                        <label>{text["label_table_search"]}</label>
                        <input type="text" className="md-input" onChange={filterTable} />
                    </div>
                </div>

                <div className="uk-width-1-1">
                    <div className='uk-overflow-auto'>
                        <table className='uk-table uk-table-hover'>
                            <thead >
                                <tr>
                                    <th ><span className="uk-text-bold">N.</span></th>
                                    {tableHeaders && tableHeaders.map((item, index) => (
                                        <th key={index}>
                                            <span className="datatable-header uk-text-bold"
                                                onClick={orderBy(item.title)}>
                                                {item.title}
                                                {order.label == item.title ? (
                                                    (order.type == "ASC" ?
                                                        <i className='material-icons'>arrow_downward</i>
                                                        :
                                                        <i className='material-icons'>arrow_upward</i>)

                                                ) : (
                                                        <i className='material-icons datatable-default-order-icon'>arrow_upward</i>
                                                    )
                                                }
                                            </span>
                                        </th>

                                    ))}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableRows && tableRows.map((row, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td className='datatable-row'>{row.date}</td>
                                        <td>
                                            <Link to={`/dashboard/eventdetail/${row.id}`}>{row.title}</Link></td>
                                        <td>{row.code}</td>
                                        <td>{row.num_of_users}</td>
                                        <td min-width="120px">{row.status}</td>
                                        <td>
                                            <i className="material-icons md-icon">more_vert</i>
                                            <div id={`more-options-${index}`} data-uk-dropdown="mode: click" className="md-dropdown-dense">
                                                <ul className="uk-nav uk-dropdown-nav">
                                                    <li data-uk-toggle={`target: #more-options-${index}`}><a onClick={onClickShare(`${row.id}`)}><i className="material-icons">share</i>{text["label_table_share_option"]}</a></li>
                                                    <li className="uk-nav-divider"></li>
                                                    <li><a onClick={onClickDelete(`${row.id}`)}><i className="material-icons">delete</i>{text["label_table_delete_option"]}</a></li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="modal-share" data-uk-modal>
                    <div className="uk-modal-dialog uk-margin-auto-vertical">
                        <div className="uk-modal-header">
                            <h2 className="uk-modal-title">{text["h3_modal_share_title"]}</h2>
                        </div>
                        <div className="uk-modal-body uk-text-center">
                            <QRCode value={`${config.main_url}/#/form/${user.currentUser.language}/${currentEvent.code}`} />
                            <p>
                                <a target="_blank" href={`${config.main_url}/#/form/${user.currentUser.language}/${currentEvent.code}`}>
                                    {`${config.main_url}/#/form/${user.currentUser.language}/${currentEvent.code}`}
                                </a>
                            </p>
                        </div>
                        <div className="uk-modal-footer uk-text-right">
                            <button className="md-btn md-btn-text uk-modal-close" type="button">{text["h3_modal_share_close"]}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Table;