import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import 'uikit/dist/css/uikit.min.css';
import UIkit from 'uikit/dist/js/uikit.js';
import MDUIkit from 'md-uikit';
import Logo from "../../assets/img/logo_sign.png";
import BGLogin from "../../assets/img/login_bg.jpg";
import { connect } from 'react-redux';
import { setCurrentUser } from '../../redux/user/user.actions';

import {config} from '../../settings.js';
class LoginPage extends Component {

    state = {
        isLogged: false,
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        ResultMessage: ''
    }

    onClickSignUp = async event => {
        const { email, password, firstname, lastname } = this.state;

        /* Validaciones */
        let flag = true;
        if (email === "" || password === "" || firstname === "" || lastname === "") {
            flag = false;
        }
        if (flag) {
            //this.setState({ ResultMessage: 'Loading...' });
            const data = {
                "email": email,
                "password": password,
                "firstname": firstname,
                "lastname": lastname,
                "rol": '1',
                "type": 'email'
            };
            console.log(data);
            var modal = UIkit.modal.dialog('<div class=\'uk-modal-body\'><p>Loading...</p></div>', { 'bgClose': false, 'escClose': false });

            fetch((`${config.api_path}/api/create_user.php`), {

                method: 'POST',
                body: JSON.stringify(data)
            })
                .then(function (response) {
                    modal.hide();
                    if (response.status >= 400 && response.status < 500) {
                        UIkit.notification('This email is registered.');
                        throw "This email is registered";
                    }
                    return response.json();
                })
                .then(data => {
                    modal.hide();
                    console.log(data);
                    this.props.history.push('./signupcomplete/');
                })
                .catch(data => {
                    UIkit.notification('Connection error. Please try later.');
                    modal.hide();
                    console.log(data);
                })
        } else {
            UIkit.notification('Please fill blank spaces');
        }
    }
    componentDidMount() {
        MDUIkit.components();
    }
    handleChange = event => {
        const { value, name } = event.target;
        this.setState({ [name]: value })
    }
    render() {
        if (this.state.isLogged) {
            return <Redirect push to="/admin" />;
        }
        return (
            <div data-uk-height-viewport data-uk-grid>
                <div className="uk-width-1-2@s" style={{ backgroundImage: `url(${BGLogin})` }}> </div>
                <div className="uk-width-1-2@s md-bg-white">
                    <div className='uk-padding'>
                        <div data-uk-grid className="uk-grid-small">
                            <div className="uk-width-1-1@m">
                                <img className="uk-align-center" src={Logo} width="80%" alt='' />
                            </div>
                            <div className="uk-width-1-2@m">
                                <div className="md-input-wrapper uk-margin-small">
                                    <label>First Name:</label>
                                    <input name="firstname" type="text" className="md-input" onChange={this.handleChange} required value={this.state.firstname} />
                                    <span className="md-input-bar"></span>
                                </div>
                            </div>
                            <div className="uk-width-1-2@m">
                                <div className="md-input-wrapper uk-margin-small">
                                    <label>Last Name:</label>
                                    <input name="lastname" type="text" className="md-input" onChange={this.handleChange} required value={this.state.lastname} />
                                    <span className="md-input-bar"></span>
                                </div>
                            </div>
                            <div className="uk-width-1-1@m">
                                <div className="md-input-wrapper uk-margin-small">
                                    <label>Email:</label>
                                    <input name="email" type="text" className="md-input" onChange={this.handleChange} required value={this.state.email} />
                                    <span className="md-input-bar"></span>
                                </div>
                            </div>
                            <div className="uk-width-1-1@m">
                                <div className="md-input-wrapper uk-margin-small">
                                    <label>Password:</label>
                                    <input name="password" type="password" className="md-input" onChange={this.handleChange} required value={this.state.password} />
                                    <span className="md-input-bar"></span>
                                </div>
                            </div>
                        </div>
                        <div className="uk-margin-medium-top">
                            <button onClick={this.onClickSignUp} className="md-btn md-color-white md-btn-primary md-btn-block md-btn-large">Sign Up</button>
                            {/* <button onClick={signInWithGoogle} className="md-btn md-color-white md-btn-primary md-btn-block md-btn-large">SIGN IN WITH GOOGLE</button> */}

                            <p>{this.state.ResultMessage}</p>

                            <p>Already have an account? <Link to={'./login'}>Sign in</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
})

const mapDispatchToProps = dispatch => ({
    setCurrentUser: user => dispatch(setCurrentUser(user))
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);