import React, { useState, useEffect } from 'react';

import UIkit from 'uikit';
import QRCode from "qrcode.react"
import { useParams, useHistory } from "react-router-dom";

import EventsDetailTable from '../../components/events_detail_table/events_detail_table.component';

import UserActions from '../../providers/user/user.actions';
import EventsActions from '../../providers/events/events.actions';

import { config, settings } from '../../settings.js';

const Home = () => {
    const [text, setText] = useState({});
    const [participantsArray, setParticipantsArray] = useState([]);
    const [termsArray, setTermsArray] = useState([]);
    const [eventInfo, setEventInfo] = useState({
        title: ""
    });

    const events = EventsActions();
    const user = UserActions();
    const history = useHistory();
    let { id } = useParams();

    useEffect(() => {
        //MDUIkit.components();
        setText(settings.language[user.currentUser.language]["events_detail"]);
        console.log('id ' + id);

        const selectedEvent = events.eventsArray.find(event => event.id === id);
        setEventInfo(selectedEvent);
        fetchParticipants();
        fetchTerms();
    }, []);
    async function fetchParticipants() {
        console.log('componentDidMount2');
        const data = {
            "event_id": id,
            "jwt": user.currentUser.token
        };
        console.log(data);
        fetch((`${config.api_path}/api/read_participants_by_event.php`), {

            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(function (response) {
                if (response.status >= 400 && response.status < 500) {
                    console.log(text);
                    UIkit.notification(text["notification_no_people"]);
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                setParticipantsArray(data);
            })
            .catch(data => {
                UIkit.notification('Error.' + data);
                console.log(data);
            })
    }
    const handleChange = event => {
        const { value, name } = event.target;
        setEventInfo({ ...eventInfo, [name]: value });
    }
    const onClickUpdate = (e) => {

        /* Validaciones */
        let flag = true;
        if (eventInfo.title === "") {
            flag = false;
        }
        if (flag) {
            const data = {
                "id": eventInfo.id,
                "title": eventInfo.title,
                "description": eventInfo.description,
                "ktr": eventInfo.ktr,
                "date": eventInfo.date,
                "place": eventInfo.place,
                "status": eventInfo.status,
                "terms": eventInfo.terms,
                "jwt": user.currentUser.token
            };
            console.log(data);
            fetch((`${config.api_path}/api/update_event.php`), {
                method: 'POST',
                body: JSON.stringify(data)
            })
                .then(function (response) {
                    if (response.status >= 400 && response.status < 500) {
                        UIkit.notification('Error.');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log(data);
                    UIkit.notification(text["notification_event_updated"]);
                    const index = events.eventsArray.findIndex(event => event.id === eventInfo.id);
                    events.eventsArray.splice(index, 1);
                    events.eventsArray.push(eventInfo);
                    events.setEventsList(events.eventsArray);
                })
                .catch(data => {
                    console.log(data);
                    UIkit.notification('Error.' + data);
                })
        } else {
            UIkit.notification('Complete los espacios en blanco');
        }

    }
    const fetchTerms = event => {
        const data = {
            "jwt": user.currentUser.token
        };
        console.log(data);
        fetch((`${config.api_path}/api/read_terms.php`), {

            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(function (response) {
                if (response.status >= 400 && response.status < 500) {
                    UIkit.notification('No hay Eventos.');
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                setTermsArray(data);
            })
            .catch(data => {
                UIkit.notification('Error.' + data);
                console.log(data);
            })
    }
    return (
        <>
            <div className="content-before"></div>
            <div className="uk-flex uk-flex-between uk-flex-middle ">
                <h2 className="md-color-white">
                    <i onClick={() => history.goBack()} className="uk-margin-top material-icons md-color-white md-icon md-36">navigate_before</i>
                    {text["h2_title"]}
                </h2>

            </div>
            <div data-uk-grid>
                <div className="uk-width-1-1@m" >
                    <div className="uk-card uk-card-body md-bg-white">
                        <div data-uk-grid className='uk-grid-small'>
                            <div className="uk-width-1-1">
                                <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled ">
                                    <label>{text["label_event_title"]}</label>
                                    <input type="text" name='title' className="md-input" required onChange={handleChange} value={eventInfo.title} />
                                </div>
                            </div>
                            <div className="uk-width-1-1">
                                <div className="md-input-wrapper md-input-wrapper-outlined md-input-textarea md-input-filled">
                                    <label>{text["label_event_description"]}</label>
                                    <textarea className="md-input" name='description' onChange={handleChange} value={eventInfo.description}></textarea>
                                </div>
                            </div>
                            <div className="uk-width-1-1">
                                <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled ">
                                    <label>{text["label_event_ktr"]}</label>
                                    <input type="text" name='ktr' className="md-input" onChange={handleChange} required value={eventInfo.ktr} />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled ">
                                    <label>{text["label_event_place"]}</label>
                                    <input type="text" name='place' className="md-input" onChange={handleChange} required value={eventInfo.place} />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled ">
                                    <label>{text["label_event_date"]}</label>
                                    <input type="date" name='date' className="md-input" onChange={handleChange} required value={eventInfo.date} />
                                </div>
                            </div>
                            <div className="uk-width-1-2@s">
                                <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled  ">
                                    <label>{text["label_event_status"]}</label>
                                    <select name="status" className="md-input" onChange={handleChange} required value={eventInfo.status}>
                                        <option value disabled hidden></option>
                                        <option value="Activo" >{text["label_event_status_active"]}</option>
                                        <option value="Inactivo" >{text["label_event_status_inactive"]}</option>
                                        <option value="Pre Registro" >{text["label_event_status_preregister"]}</option>
                                    </select>
                                    <span className="md-input-bar "></span>
                                </div>
                            </div>
                            <div className="uk-width-1-2@s">
                                <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled  ">
                                    <label>{text["label_event_terms"]}</label>
                                    <select name="terms" className="md-input" onChange={handleChange} required value={eventInfo.terms}>
                                        <option value disabled hidden></option>
                                        {termsArray && termsArray.map((item, index) => (
                                            <option value={item.id} >{item.name}</option>
                                        ))}
                                    </select>
                                    <span className="md-input-bar "></span>
                                </div>
                            </div>

                            <div className="uk-width-1-1">
                                <div align="right" >
                                    <button data-uk-toggle="target: #modal-share" className="md-btn md-color-white md-btn-primary ripple-surface"><i className='material-icons'>share</i> {text["export_share"]}</button>
                                    <button onClick={onClickUpdate} className="md-btn md-color-white md-btn-primary ripple-surface"><i className='material-icons'>save</i>{text["label_update_info"]}</button>
                                </div>
                                <div id="modal-share" data-uk-modal>
                                    <div className="uk-modal-dialog uk-margin-auto-vertical">
                                        <div className="uk-modal-header">
                                            <h2 className="uk-modal-title">{text["h2_share_event_title"]}</h2>
                                        </div>
                                        <div className="uk-modal-body uk-text-center">
                                            <QRCode value={`${config.main_url}/#/form/${user.currentUser.language}/${eventInfo.code}`} /> <br />
                                            <a target="_blank" href={`${config.main_url}/#/form/${user.currentUser.language}/${eventInfo.code}`}>{`${config.main_url}/#/form/${user.currentUser.language}/${eventInfo.code}`}</a>
                                        </div>
                                        <div className="uk-modal-footer uk-text-right">
                                            <button className="md-btn md-btn-text uk-modal-close" type="button">{text["text_close"]}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="uk-card uk-card-body md-bg-white">
                        <h4>Participantes</h4>
                        {participantsArray.length ? (
                            <>
                                <div align="right" >

                                    <a href={`${config.api_path}/reports/download_excel_participants_opal.php?Id=${eventInfo.id}&lang=${user.currentUser.language}`} className='md-btn md-color-white md-bg-green-900 ripple-surface'>{text["export_to_excel_opal"]}</a>
                                    <a href={`${config.api_path}/reports/download_excel_participants.php?Id=${eventInfo.id}&lang=${user.currentUser.language}`} className='md-btn md-color-white md-bg-green-900 ripple-surface'>{text["export_to_excel"]}</a>

                                    <a href={`${config.api_path}/reports/GenerarFichadeDatos.php?Id=${eventInfo.id}&lang=${user.currentUser.language}`} className='md-btn md-color-white md-bg-red-900 ripple-surface'>{text["export_to_pdf"]}</a>
                                </div>
                                <br />
                                < EventsDetailTable participantsArray={participantsArray} />
                            </>
                        ) : (
                                <p>No se encontro particiantes en este evento.</p>
                            )}

                    </div>
                </div>
            </div>
        </>

    );
}
export default Home;