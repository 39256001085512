import React, { useEffect, useState } from 'react'

import UIkit from 'uikit';
import MDUIkit from 'md-uikit'

import UserActions from '../../providers/user/user.actions';
import { config, settings } from '../../settings.js';

const Reports = () => {

    const user = UserActions();
    const [text, setText] = useState({});
    const [searchInput, setSearchInput] = useState({})
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        MDUIkit.components();
        setText(settings.language[user.currentUser.language]["reports"]);
    }, []);

    const handleChange = event => {
        const { value, name } = event.target;
        setSearchInput({ ...searchInput, [name]: value })
    }

    async function getReport() {
        setIsLoading(true);
        try {
            const data = {
                "date_from": searchInput.from,
                "date_to": searchInput.to,
                "user_id": user.currentUser.Id
            };
            console.log(data);
            const response = await fetch((`${config.api_path}/api/report_event.php`), {
                method: 'POST',
                body: JSON.stringify(data)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();
            console.log(res);
            setData(res);
            setIsLoading(false);
        } catch (error) {
            console.log(error)
            UIkit.notification('Error.' + error);
        }
    }
    const onClickSearch = event => {
        console.log("onClickSearch");
        let flagWhites = true;
        let flagGeneral = true;

        if (searchInput.from == undefined || searchInput.to == undefined || searchInput.reportType == undefined) {
            flagWhites = false;
        }
        if (!flagWhites) {
            flagGeneral = false;
            UIkit.notification('Complete los espacios en blanco.', 'danger');
        }
        if (flagGeneral) {
            console.log("ready");
            getReport();
        }
    }
    return (
        <>
            <div className="content-before"></div>
            <h2 className="md-color-white">{text["h2.title"]}</h2>
            <div data-uk-grid>
                <div className="uk-width-1-1" >
                    <div className="uk-card uk-card-body md-bg-white">
                        <div data-uk-grid className="uk-grid-small">
                            <div className="uk-width-1-1">
                                <div className="md-input-wrapper md-input-wrapper-outlined">
                                    <label>{text["search_type"]}</label>
                                    <select className="md-input" onChange={handleChange} name='reportType' value={searchInput.reportType}>
                                        <option disabled selected hidden></option>
                                        <option value="people_per_event">{text["search_option_att_per_event"]}</option>
                                    </select>
                                    <span className="md-input-bar "></span>
                                </div>
                            </div>
                            <div className="uk-width-1-2@m">
                                <div className="md-input-wrapper md-input-filled md-input-wrapper-outlined ">
                                    <label>{text["search_from"]}</label>
                                    <input name="from" type="date" className="md-input" onChange={handleChange} value={searchInput.from} />
                                    <span className="md-input-bar"></span>
                                </div>
                            </div>
                            <div className="uk-width-1-2@m">
                                <div className="md-input-wrapper md-input-filled md-input-wrapper-outlined">
                                    <label>{text["search_to"]}</label>
                                    <input name="to" type="date" className="md-input" onChange={handleChange} value={searchInput.to} />
                                    <span className="md-input-bar"></span>
                                </div>
                            </div>
                            <div className="uk-width-1-1" align="right">
                                <button onClick={onClickSearch} className="md-btn md-btn-primary ">{text["search_text"]}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-1" >
                    {data.length > 0 &&
                        <div className="uk-card uk-card-body md-bg-white">
                            <div className="uk-grid-small" data-uk-grid>
                                <h4>{text["search_results"]}</h4>
                                <div className="uk-width-1-1" align="right">
                                    <a href={`${config.api_path}/reports/download_excel_report_events.php?date_from=${searchInput.from}&date_to=${searchInput.to}&user_id=${user.currentUser.Id}&lang=${user.currentUser.language}`}
                                        className='md-btn md-color-white md-bg-green-900 ripple-surface'>{text["export_to_excel"]}</a>
                                </div>
                                <div className="uk-width-1-1" >
                                    {searchInput.reportType == "people_per_event" &&
                                        <div className="uk-overflow-auto">
                                            <table className="uk-table uk-table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>{text["report_date"]}</th>
                                                        <th>{text["report_title"]}</th>
                                                        <th>KTR</th>
                                                        <th>{text["report_email"]}</th>
                                                        <th>{text["report_firstname"]}</th>
                                                        <th>{text["report_lastname"]}</th>
                                                        <th>{text["report_phone"]}</th>
                                                        <th>{text["report_city"]}</th>
                                                        <th>{text["report_country"]}</th>
                                                        <th>{text["report_institution"]}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.map(item => (
                                                        <tr>
                                                            <td>{item.date}</td>
                                                            <td>{item.title}</td>
                                                            <td>{item.ktr}</td>
                                                            <td>{item.email}</td>
                                                            <td>{item.firstname}</td>
                                                            <td>{item.lastname}</td>
                                                            <td>{item.phone}</td>
                                                            <td>{item.city}</td>
                                                            <td>{item.country}</td>
                                                            <td>{item.institution}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {isLoading && <span>{text["loading"]}</span>}
                </div>

            </div>
        </>
    )
}

export default Reports
